<template>
  <div>
    <navbar
        :items="navbarItems"
        :fonticon="pageIcon"
        :navbartitle="pageTitle"
        :navbardescription="pageDescription"
      />
      <div class="card-box">
          <data-table
            :data-source="filteredGardes"
            :headers="headers"
            able-class="table-striped table-hover"
            @search="search"
        >
            <template slot="actions">
            
            <a 
                href="javascript:void(0)" 
                class="btn btn-outline-primary btn-sm"
                @click.prevent="filter=null"
            >
                Tout ({{ gardes.length }})
            </a>
            <a
                href="javascript:void(0)"
                class="btn btn-outline-warning btn-sm"
                @click.prevent="filter='OPEN'"
            >
                Ouvert ({{ openGardes.length }})
            </a>
            <a
                href="javascript:void(0)" 
                class="btn btn-outline-success btn-sm"
                @click.prevent="filter='CLOSED'"
            >
                Fermé ({{ closedGardes.length }})
            </a>
            
            <router-link
                href="javascript:void(0)"
                class="btn btn-outline-dark btn-sm"
                :to="{name: 'garde-add'}"
            >
                <i class="icofont icofont-plus"></i>
            </router-link>
            </template>
        </data-table>
      </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment'
import Navbar from '../../components/navbar.vue';
  import DataTable from '../../components/dataTable/local.vue'
import { COMPONENT_TYPE, DATE_TYPE, TEXT_TYPE } from '../../components/dataTable/dataType';
const Animal = () => import('../../components/sanitaire/animal.vue')
const Actions = () => import('../../components/garderie/garde/actions.vue')
const Type = () => import('../../components/garderie/garde/type.vue')
const Cabine = () => import('../../components/garderie/garde/cabine.vue')
export default {
    components: {Navbar, DataTable},
    data(){
        return {
            navbarItems: [
                  {   
                      libelle: "Garderie", 
                      route: 'garderie'
                  },
                  {
                      libelle: 'Gardes'
                  }
              ],
              pageIcon: 'la-calendar',
              pageTitle: 'Garderie',
              pageDescription: 'List des gardes CASEAC',
              filter: null,
              searchGardes: []
        }
    },
    mounted(){ this.searchGardes =  this.gardes},
    watch:{
        gardes: {
            deep: true,
            handler(){
                this.searchGardes = this.gardes
            }
        }
    },
    methods:{
        search(payload){
          const query = payload.search
              if(query === '') this.searchGardes = this.gardes
              else{
                this.searchGardes = this.gardes.filter(garde =>
                  this.$options.filters.deepSearch(garde, query)|| 
                  this.$options.filters.deepSearch(this.animaux.find(animal => animal.uid === garde.animal), query)||
                  this.$options.filters.deepSearch(this.types.find(type => type.uid === garde.type), query)||
                  this.$options.filters.deepSearch(this.cabines.find(cabine => cabine.uid === garde.cabine), query)
                )
              }
        }
    },
    computed: {
        ...mapGetters({
            gardes: 'garderie/gardes',
            animaux: 'identification/animaux',
            types: 'garderie/types',
            cabines: 'garderie/cabines',
            is_super_admin: 'auth/is_super_admin',
        }),
        filteredGardes(){
            if(this.filter){
                return [...this.searchGardes].filter(g => g.status === this.filter).sort((b,a) => moment(a.startDate).format('X') - moment(b.startDate).format('X'))
            }
            return [...this.searchGardes].sort((b,a) => moment(a.startDate).format('X') - moment(b.startDate).format('X'))
        },
        openGardes(){
            return [...this.searchGardes].filter(g => g.status === 'OPEN')
        },
        closedGardes(){
            return [...this.searchGardes].filter(g => g.status === 'CLOSED')
        },
        headers(){
            return [
                {label: 'UID', name: 'uid', type: TEXT_TYPE, visible: this.is_super_admin},
                {label: 'Animal', name: 'animal', type: COMPONENT_TYPE, component: Animal},
                {label: 'Date de début', name: 'startDate', type: DATE_TYPE},
                {label: 'Date de fin', name:'endDate', type: DATE_TYPE},
                {label: 'Type', name:'type', type: COMPONENT_TYPE, component: Type},
                {label: 'Cabine', name:'cabine', type: COMPONENT_TYPE, component: Cabine},
                {label: 'Status', name:'status', type: TEXT_TYPE},
                {label: 'Actions', name: 'actions', type: COMPONENT_TYPE, component: Actions}
            ]
        }
    }

}
</script>